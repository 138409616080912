import React, {Component}  from 'react';
import { withAuthenticator } from 'aws-amplify-react';
import { Storage } from 'aws-amplify';
import logo, { ReactComponent } from './logo.svg';
import './App.css';

class App extends Component {
  state = {dec: '', nov: '', oct: '',sept: '', aug: '', jul: '', jun: '', may:'', april: '', mar: '', feb: '', jan: ''}
  componentDidMount() {
    Storage.get('201912_CCA_RevRpt.pdf', {expires: 300})
    .then(data => {
      this.setState({
        dec: data
      })
    }) 
     Storage.get('201911_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          nov: data
        })
      })  
      Storage.get('201910_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          oct: data
        })
      })    
      Storage.get('201909_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          sept: data
        })
      })
      Storage.get('201908_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          aug: data
        })
      })
      Storage.get('201907_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          jul: data
        })
      })
      Storage.get('201906_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          jun: data
        })
      })
      Storage.get('201905_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          may: data
        })
      })
      Storage.get('201904_CCA_RevRpt.pdf', {expires: 300})
      .then(data => {
        this.setState({
          april: data
        })
      })
      Storage.get('201903_CCA_RevRpt.pdf', {expires: 60})
      .then(data => {
        this.setState({
          mar: data
        })
      })
      Storage.get('201902_CCA_RevRpt.pdf', {expires: 60})
      .then(data => {
        this.setState({
          feb: data
        })
      })
      Storage.get('201901_CCA_RevRpt.pdf', {expires: 60})
      .then(data => {
        this.setState({
          jan: data
        })
      })
      .catch (err => {
        console.log('error fetching file' + err)
      })

  }
 
  render() {
    return (
      <div className="App" style={{ padding: '0px 0px 40px'}}>
        <h1>Available Reports</h1>
        {/* <img src={this.state.fileUrl} /> */}
                                         
        <div class="row">
  <div class="col-md-12 school-options-dropdown text-center">
    <div class="dropdown btn-group">

      <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Select a Report &nbsp; 
        <span class="caret"> </span>
      </button>

      <ul class="dropdown-menu">
        <li><a href={this.state.dec} target="blank">December 2019 Revenue</a></li>
        <li><a href={this.state.nov} target="blank">November 2019 Revenue</a></li>
        <li><a href={this.state.oct} target="blank">October 2019 Revenue</a></li>
        <li><a href={this.state.sept} target="blank">September 2019 Revenue</a></li>
        <li><a href={this.state.aug} target="blank">August 2019 Revenue</a></li>
        <li><a href={this.state.jul} target="blank">July 2019 Revenue</a></li>
        <li><a href={this.state.jun} target="blank">June 2019 Revenue</a></li>
        <li><a href={this.state.may} target="blank">May 2019 Revenue</a></li>
        <li><a href={this.state.april} target="blank">April 2019 Revenue</a></li>
        <li><a href={this.state.mar} target="blank">March 2019 Revenue</a></li>
        <li><a href={this.state.feb} target="blank">February 2019 Revenue</a></li>
        <li><a href={this.state.jan} target="blank">January 2019 Revenue</a></li>
      </ul>

    </div>
  </div>
</div>
      </div>
    )
  }

}

const MyTheme = 
{

    backgroundColor: '#4652',
    alignItems: 'center',
    justifyContent: 'center'
  };

//need to edit the greeting
//export default withAuthenticator(App, { includeGreetings: true, theme: MyTheme });
export default withAuthenticator(App);